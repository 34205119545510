import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import getDistance from "../../utils/getDistance"
import findCenter from '@src/utils/findCenter';


export const getBranch = createAsyncThunk("branch/getBranch", async () => {
  const itemsPerPage = 100;
  const numberOfPages = 5;

  let allBranches = [];

  try {
    for (let page = 1; page <= numberOfPages; page++) {
      const apiUrl = `${process.env.GATSBY_API_URL}/branch?_fields=id,title,meta.lat,meta.lng,meta.branchImg,meta.branchPhones,meta.branchEmails,link,meta._reapitId,meta._reapitIdLetting,meta.instagramLink,meta.instagramText,meta.branchParents,meta.excludeForms,meta.branchTitle&per_page=${itemsPerPage}&page=${page}`;

      const response = await fetch(apiUrl);
      const data = await response.json();

      allBranches = [...allBranches, ...data];

      if (data.length < itemsPerPage) {
        break;
      }
    }

    //console.log('getBranch', allBranches);
    return allBranches;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
});


const getMainBranch = (branch) => {
  return branch.filter(branch => !branch.meta.branchParents.length);
}

const branchSlice = createSlice({
  name: "branch",
  initialState: {
    mainBranch: [],
    entities: [], // main branch
    loading: false,
    sortOrder: "title-asc",
    center: null,
    branchLocation: {
			address : null,
		},
  },
  reducers: {
    sortBranch: (state, action) => {
      const { sortOrder, location, main = false } = action.payload
      //console.log('main sort branch', main, action.payload, sortOrder);

      state.sortOrder = sortOrder

      let branch =  main ? state?.mainBranch : state.entities;
      if (sortOrder === "title-asc") {
        branch.sort(function (a, b) {
          if (a.title.rendered < b.title.rendered) {
            return -1
          }
          if (a.title.rendered > b.title.rendered) {
            return 1
          }
          return 0
        })
      }

      if (sortOrder === "title-desc") {
        branch.sort(function (a, b) {
          if (a.title.rendered > b.title.rendered) {
            return -1
          }
          if (a.title.rendered < b.title.rendered) {
            return 1
          }
          return 0
        })
      }

      if (sortOrder === "distance") {
        if (typeof location == "undefined") return
        const { lat: refLat, lng: refLng } = location
        branch.sort((a, b) => {
          const aDistance = getDistance(a.meta.lat, a.meta.lng, refLat, refLng)
          a.distance = aDistance

          const bDistance = getDistance(b.meta.lat, b.meta.lng, refLat, refLng)
          b.distance = bDistance

          return aDistance - bDistance
        })
      }
    },
    updateBranch: (state, action, main = false) => {
      const branchData = action.payload
      let branch =  main ? state?.mainBranch : state.entities;
      branch = branchData
    },
    updateCenter: (state, action) => {
        const latLngList = [];
        state.entities.map((branch) => {
          let lat = branch.meta.lat;
          let lng = branch.meta.lng;
          latLngList.push({ lat, lng})
        })
        const center = findCenter(latLngList);
        console.log('latLngList center', center);
        state.center = center;
    },
    updateSelectedLocation: (state, action) => {
			const { page, location} = action.payload;
			state.branchLocation = location;
		},
  },
  extraReducers: {
    [getBranch.pending]: state => {
      state.loading = true
    },
    [getBranch.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.entities = payload
      
      state.mainBranch = getMainBranch(payload);
    },
    [getBranch.rejected]: state => {
      state.loading = false
    },
  },
})

export const { updateSelectedLocation, sortBranch, updateBranch } = branchSlice.actions

export default branchSlice.reducer
