import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getMiscellaneousData = createAsyncThunk(
	'miscellaneousData/getMiscellaneousData',
	async () => {
        const response = await fetch(`${process.env.GATSBY_WP_SITEURL}/wp-json/twentytwo/api/v1/globals-settings/?name=miscellaneous`)
			            .then((response) => response.json());

        return response;
	}
)


const commonState = createSlice({
	name: 'commonState',
	initialState: {
		googleScriptLoaded : false,
		loading: false,
		miscellaneousData: {},
		pgp:'',
		pageBranding:'',
	},
	reducers: {
		updateGoogleScriptState: (state, action) => {
			const isLoaded = action.payload;
			state.googleScriptLoaded = isLoaded
		},
		updatePgpPathState: (state, action) => {
			const value = action.payload;
			state.pgp = value
		},
		updatePageBrandingState: (state, action) => {
			const value = action.payload;
			state.pageBranding = value
		}
	},
	extraReducers: {
        [getMiscellaneousData.pending]: (state) => {
            state.loading = true
        },
        [getMiscellaneousData.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.miscellaneousData = payload?.data
        },
        [getMiscellaneousData.rejected]: (state) => {
            state.loading = false
        },
    },
})

export const { updateGoogleScriptState, updatePgpPathState, updatePageBrandingState } = commonState.actions

export default commonState.reducer;
