import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const getProeprtyTypes = createAsyncThunk(
	'property/getProeprtyTypes',
	async () => {
        let apiUrl = `${process.env.HIVE_API_URL}/property_type?per_page=100&_fields=id,slug,name&parent=0`
        const response = await fetch(apiUrl)
			                  .then((response) => response.json());
        return response;
	}
)

const propertySearchSlice = createSlice({
  name: "property-search",
  initialState: {
    data: {},
    settings: {},
    pgp:'',
    propertyTypes: {}
  },
  reducers: {
    updateSearchState: (state, action) => {
    
      state.settings = action.payload
    },
    updatePgpPathState: (state, action) => {
			const value = action.payload;
			state.pgp = value
		}
  },
  extraReducers: {
    [getProeprtyTypes.pending]: (state) => {
        state.loading = true
    },
    [getProeprtyTypes.fulfilled]: (state, {payload}) => {
        state.loading = false
        state.propertyTypes = payload
    },
    [getProeprtyTypes.rejected]: (state) => {
        state.loading = false
    },
},
})

export const { updateSearchState, updatePgpPathState } = propertySearchSlice.actions
export default propertySearchSlice.reducer
