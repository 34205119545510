import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const isHomePage = () => {
    if (typeof window !== 'undefined') {
      const pathSegments = window.location.pathname.split('/');
      const filteredSegments = pathSegments.filter((segment) => segment.trim() !== '');
      const slug = filteredSegments.length > 0 ? filteredSegments[filteredSegments.length - 1] : 'home';
      return slug === 'home';
    }
    return true;
};
console.log('isHomePage', isHomePage())

const modals = createSlice({
    name: 'modals',
    initialState : {
        personalizeFormShown: isHomePage()
    },
    reducers: {
        togglePersonalize: (state, action) => {
            state.personalizeFormShown =  action.payload;
        }
    }
})

export const { togglePersonalize } = modals.actions

export default modals.reducer;
