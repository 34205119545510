const calculateCenter = (latLngList) => {
    if (latLngList.length === 0) {
      return { lat: 0, lng: 0 }; // Default center if the list is empty
    }
  
    // Calculate the average latitude and longitude
    const totalLat = latLngList.reduce((acc, latLng) => acc + latLng.lat, 0);
    const totalLng = latLngList.reduce((acc, latLng) => acc + latLng.lng, 0);
  
    const center = {
      lat: totalLat / latLngList.length,
      lng: totalLng / latLngList.length,
    };
  
    return center;
  }

  export default calculateCenter;