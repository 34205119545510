import { createSlice } from '@reduxjs/toolkit'
const user = createSlice({
	name: 'user',
	initialState: {
		userLocation: {
			address : null,
		},
		userType: null,
		neareastBranchID: null,
	},
	reducers: {
		updateUserLocation: (state, action) => {
			const { page, location} = action.payload;
			state.userLocation = location
		},
		updateUserType: (state, action) => {
			state.userType = action.payload
		},
		updateNeareastBranchID: (state, action) => {
			state.neareastBranchID = action.payload;
		},
	},
})

export const { updateUserLocation, updateUserType, updateNeareastBranchID } = user.actions

export default user.reducer;

